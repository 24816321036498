import { defineStore } from 'pinia';
import listingsJson from '~/data/listings.json';
import store from '~/data/store.json';
const { countries, rates, reviews } = store;
import { listingsDataBuilder } from '~/utils/listingUtil';
const allListing = listingsDataBuilder(listingsJson, rates, reviews);
import { getRandomSubset } from '~/utils/objectHelpers';
export const useListingsStore = defineStore('listings', {
    state: () => ({
        listings: allListing,
        rates: rates,
        countries: countries,
        reviews: reviews,
        curratedListings: ['Airport Transfers',
            // 'Shared Minivan Transfer',
            'Transportation Options',
            // 'Private Transfers',
            // 'Private Transfers with Underground River Tour',
            // 'Puerto Princesa Underground River Tour',
            'Tours in Puerto Princesa',
            // 'El Nido Joining Boat Tours',
            // 'El Nido Private Boat Tours',
            'El Nido Island Tours and Activities',
        ].map((title) => slugLibrary(`/${slugify(title)}`, allListing)),
    }),
    actions: {

    },
    getters: {
        getListingsBySlug: (state) => (slug) => state.listings.find(listing => listing.slug === slug),
        getBestsellers: (state) => getRandomSubset(6, state.listings.filter(listing => listing.isBestSeller)),
        getCurratedListings: (state) => (slug) => state.curratedListings.find(listing => listing.slug === slug) || slugLibrary(slug, state.listings),
        getSuggestions: (state) => (code, origin, destination) => {
            const listings = state.listings.filter(listing => (listing.destination === origin || listing.destination === destination || listing.origin === origin || listing.origin === destination) && listing.code !== code);
            return getRandomSubset(6, listings);

        },
    },
    persist: {
        storage: persistedState.cookiesWithOptions(
            {
                maxAge: 60 * 60 * 48,
            }
        )
    }
});