<template>
	<v-bottom-sheet v-model="getIsSelectDiagOpen" persistent width="500" location="center"
		content-class="v-bottom-sheet">
		<v-list density="compact">
			<v-list-subheader>Select Currency</v-list-subheader>
			<v-text-field class="mx-auto px-2" placeholder="Search currency" hint="Search currency"
				v-model="searchQuery" variant="underlined" color="primary" density="compact"></v-text-field>

			<v-virtual-scroll v-if="getIsSelectDiagOpen" class="mx-2 d-block" :items="filteredItems" height="400">
				<template v-slot:default="{ item }">
					<v-list-item :value="item" @click="changeCountry(item.countryCode)" two-line>
						<v-list-item-title v-text="`${item.flag} ${item.countryName}`"></v-list-item-title>
						<v-list-item-subtitle
							v-text="`${generateTitle(item.name)} (${item.currencyCode})`"></v-list-item-subtitle>
					</v-list-item>
				</template>
			</v-virtual-scroll>
		</v-list>
	</v-bottom-sheet>
</template>
<script setup>
const store = useBookingStore();
const currencyStore = useCurrencyStore();
const listingStore = useListingsStore();
const { getIsSelectDiagOpen } = storeToRefs(store);
const { countries } = storeToRefs(listingStore);
const filteredItems = ref([]);
const searchQuery = ref('');

const generateTitle = (text) => {
	const words = text.split(' ');

	return words
		.map((word) => {
			return word[0].toUpperCase() + word.substring(1);
		})
		.join(' ');
};
watch(searchQuery, (value) => {
	filteredItems.value = countries.value.filter((item) => {
		return item.countryName.toLowerCase().includes(value.toLowerCase()) || item.currencyCode.toLowerCase() == value.toLowerCase() || item.name.toLowerCase().includes(value.toLowerCase());
	});
});
onMounted(() => {
	filteredItems.value = [...countries.value];
});

const changeCountry = (countryCode) => {
	const currencyData = countries.value.find((item) => item.countryCode === countryCode);
	store.setState(currencyData);
	currencyStore.setInitialData(currencyStore.locale, currencyData);
};
</script>
