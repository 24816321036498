import { generateImages, imageURLGenerator, getRandomSubset, shuffleArray } from '~/utils/objectHelpers';
import { getSEOContent } from './currateSeo';
import { slugify } from '.';
const currateList = [
	{
		img: imageURLGenerator('VAN')[0],
		title: 'Airport Transfers',
	},
	{
		img: imageURLGenerator('VAN')[0],
		title: 'Shared Minivan Transfer',
	},
	{
		img: imageURLGenerator('VAN')[0],
		title: 'Transportation Options',
	},
	{
		img: imageURLGenerator('VAN')[0],
		title: 'Private Transfers',
	},
	{
		img: imageURLGenerator('UR')[0],
		title: 'Private Transfers with Underground River Tour',
	},
	{
		img: imageURLGenerator('UR')[0],
		title: 'Puerto Princesa Underground River Tour',
	},
	{
		img: imageURLGenerator('UR')[0],
		title: 'Tours in Puerto Princesa',
	},
	{
		img: imageURLGenerator('ELN-A')[0],
		title: 'El Nido Joining Boat Tours',
	},
	{
		img: imageURLGenerator('ELN-C')[0],
		title: 'El Nido Private Boat Tours',
	},
	{
		img: imageURLGenerator('ELN-B')[0],
		title: 'El Nido Island Tours and Activities',
	},
];
export const requiredParticipants = (listings, code: string) => {
	const tourTypes = ['PRIVATE_TRANSFER_WITH_TOUR', 'JOINING_TOUR', 'PRIVATE_BOAT_TOUR', 'AIRPORT_TRANSFER', 'TOUR_PACKAGE_LOCAL', 'TOUR_PACKAGE_INTL'];
	const listing = listings.find((listing) => listing.code === code);
	return listing && tourTypes.includes(listing.listing_type);
};

export const slugLibrary = (query: string, StateListings: any) => {
	let data: { img: string; title: string; listings?: any[], seo_content?: string } = currateList.find((i) => `/${slugify(i.title)}` === query);
	let listingItems = [
		{ slug: '/shared-minivan-transfer', listings: StateListings.filter((item) => item.code.includes('SV') && item.listing_type === 'SHARED_TRANSFER') },
		{ slug: '/private-transfers', listings: StateListings.filter((item) => item.code.includes('PV') && !item.code.includes('PPUR')) },
		{ slug: '/puerto-princesa-underground-river-tour', listings: StateListings.filter((item) => item.code.includes('PPUR')) },
		{ slug: '/tours-in-puerto-princesa', listings: StateListings.filter((item) => ['JOINING_TOUR', 'PRIVATE_BOAT_TOUR', 'PRIVATE_TRANSFER_WITH_TOUR'].includes(item.listing_type) && item.destination === 'PPS') },
		{ slug: '/el-nido-joining-boat-tours', listings: StateListings.filter((item) => ['JOINING_TOUR'].includes(item.listing_type) && item.origin === 'ELN') },
		{ slug: '/el-nido-private-boat-tours', listings: StateListings.filter((item) => ['PRIVATE_BOAT_TOUR'].includes(item.listing_type) && item.origin === 'ELN') },
		{ slug: '/private-transfers-with-underground-river-tour', listings: StateListings.filter((item) => item.code.includes('PV') && item.code.includes('PPUR')) },
		{ slug: '/el-nido-island-tours-and-activities', listings: StateListings.filter((item) => ['JOINING_TOUR', 'PRIVATE_BOAT_TOUR'].includes(item.listing_type) && item.origin === 'ELN') },
		{ slug: '/puerto-princesa-to-port-barton', listings: StateListings.filter((item) => item.destination === 'PBT' && item.origin === 'PPS') },
		{ slug: '/port-barton-to-puerto-princesa', listings: StateListings.filter((item) => item.destination === 'PPS' && item.origin === 'PBT') },
		{ slug: '/el-nido-island-hopping-tour-a', listings: StateListings.filter((item) => item.code.includes('ELN-A')) },
		{ slug: '/el-nido-island-hopping-tour-b', listings: StateListings.filter((item) => item.code.includes('ELN-B')) },
		{ slug: '/el-nido-island-hopping-tour-c', listings: StateListings.filter((item) => item.code.includes('ELN-C')) },
		{ slug: '/el-nido-island-hopping-tour-d', listings: StateListings.filter((item) => item.code.includes('ELN-D')) },
		{ slug: '/puerto-princesa-to-el-nido', listings: StateListings.filter((item) => item.destination === 'ELN' && item.origin === 'PPS') },
		{ slug: '/el-nido-to-puerto-princesa', listings: StateListings.filter((item) => item.destination === 'PPS' && item.origin === 'ELN') },
		{ slug: '/puerto-princesa-to-sabang', listings: StateListings.filter((item) => item.destination === 'SAB' && item.origin === 'PPS') },
		{ slug: '/sabang-to-puerto-princesa', listings: StateListings.filter((item) => item.destination === 'PPS' && item.origin === 'SAB') },
		{ slug: '/el-nido-to-sabang', listings: StateListings.filter((item) => item.destination === 'SAB' && item.origin === 'ELN') },
		{ slug: '/sabang-to-el-nido', listings: StateListings.filter((item) => item.destination === 'ELN' && item.origin === 'SAB') },
		{ slug: '/el-nido-to-port-barton', listings: StateListings.filter((item) => item.destination === 'PBT' && item.origin === 'ELN') },
		{ slug: '/port-barton-to-el-nido', listings: StateListings.filter((item) => item.destination === 'ELN' && item.origin === 'PBT') },
		{ slug: '/transportation-options', listings: StateListings.filter((item) => !['PRIVATE_BOAT_TOUR', 'JOINING_TOUR'].includes(item.listing_type)) },
	];
	const listingsData = listingItems.find((i) => i.slug === query) || { listings: [], slug: query };
	if (!data) {
		const images = shuffleArray(listingsData.listings.map((i) => i.thumbnail))[0];
		data = { img: images as string, title: query.replace(/-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()).replace('/', ''), listings: listingsData?.listings || [] };
	}
	return {
		listings: listingsData?.listings || [],
		slug: query,
		slugs: listingItems.map((i) => i.slug),
		seo_content: getSEOContent(query),
		...data,
	};
};

export const listingsDataBuilder = (listings: any[], rates = {}, reviews = []) => {
	return listings.map((i) => {
		const ratePlan = JSON.parse(JSON.stringify(i.ratePlan));
		const { images, thumbnail } = generateImages(i);

		const ratePlans = Object.entries(rates).reduce((acc, [key, value]) => {
			const currencyRate = parseFloat(value.toString());
			acc[key] = { base: Math.round(parseFloat(ratePlan.base) * currencyRate), inc: parseInt(ratePlan.inc), extra: Math.round(parseFloat(ratePlan.extra) * currencyRate), currency: key };
			return acc;
		}, {});

		return {
			...i,
			chips: generateChips(i),
			ratePlans,
			images,
			thumbnail,
			reviews: getRandomSubset(8, reviews.filter((rev) => rev.reviewRating > 4)),
			isBestSeller: ['PV-PPS-ELN', 'PV-PPUR-PPS-ELN', 'PV-ELN-PPS', 'PV-PPUR-ELN-PPS', 'PV-PPS-PBT', 'PV-PBT-ELN', 'SV-PPS-ELN', 'SV-ELN-PPS'].includes(i.code),
		};
	});
};


