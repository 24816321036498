export const getSEOContent = (slug: string) => {
    if (slug === '/private-transfers') {
        return `
        <h1>Travel the Roads of Palawan in Comfort and Style!</h1>
        <p>Looking for the most convenient way to explore the “Best Island in the World”? Private van transfers are the ideal solution for you and your group to travel across Palawan with ease.</p>
        
        <h2>Our Routes:</h2>
        <ul>
            <li>Puerto Princesa</li>
            <li>El Nido</li>
            <li>Port Barton</li>
            <li>San Vicente</li>
        </ul>
        
        <h2>Special Pick-Up or Drop-Off Areas:</h2>
        <ul>
            <li>Villa Libertad</li>
            <li>Lio</li>
            <li>Nacpan</li>
            <li>Bucana</li>
            <li>San Fernando</li>
            <li>Sibaltan</li>
            <li>Taytay</li>
            <li>Roxas</li>
            <li>Sabang</li>
            <li>...and more!</li>
        </ul>
        
        <p>Our goal is to make your journey as smooth as possible. If you have any questions or special requests, don’t hesitate to reach out—we’re happy to assist in organizing your private transportation needs across Palawan.</p>
        
        <div class="offer">
            <strong>Exclusive Offer:</strong> Enjoy a discount on your second private van booking! Simply contact us after booking your first trip to avail this special deal.
        </div>
        
       
    `
    }
    return null;
}