import { defineStore } from 'pinia';
import { apiPost } from '~/utils/api';
export const useBookingStore = defineStore('booking', {
	state: () => ({
		bookingRef: makeRef(),
		booker: {
			name: null,
			email: null,
			mobile: null,
		},
		userCountry: 'US',
		currency: 'USD',
		cartItems: [],
		isBookerInfoOpened: false,
		isMobile: false,
		isBookingDiagOpen: false,
		conversionData: {
			value: 0,
			currency: 'USD',
			transaction_id: null,
		},
		searchResults: [],
		searchData: null,
		placeSearchCache: [],
		currencies: [],
		bestsellers: [],
		rates: null,
		currencyData: null,
		countries: [],
		currentRates: 1,
		isSelectDiagOpen: false,
		userLocale: 'en-US',
		listings: [],
		reviews: [],
		locale: 'en-US',
		isGranted: false,
	}),

	actions: {
		resetState() {
			this.bookingRef = makeRef();
			this.booker = {
				name: null,
				email: null,
				mobile: null,
			};
			this.userCountry = 'US';
			this.cartItems = [];
			this.isBookerInfoOpened = false;
			this.isMobile = false;
			this.isBookingDiagOpen = false;
			this.conversionData = {
				value: 0,
				currency: 'USD',
				transaction_id: null,
			};
			this.searchResults = [];
			this.searchData = null;
			this.placeSearchCache = [];
		},

		setInitData(rates, countries, reviews, listings) {
			this.countries = countries;
			this.rates = rates;
			this.reviews = reviews;
			this.listings = listings;
		},
		setCountries(countries) {
			this.countries = countries;
		},

		setState(countryData) {

			const userCurrency = this.countries.find((item) => item.countryCode === countryData?.countryCode)?.currencyCode;

			if (this.currency !== userCurrency) {
				this.currency = userCurrency;
			}
			this.currentRates = this.rates[this.currency];
			this.currencyData = this.countries.find((item) => item.currencyCode === this.currency && item.countryCode === countryData?.countryCode);
			this.currentRates = this.rates[this.currency];
			this.isSelectDiagOpen = false;
			this.userLocale = countryData?.locale;
			this.locale = countryData?.locale;
		},

		setIsBookingDiagOpen() {
			this.isBookingDiagOpen = !this.isBookingDiagOpen;
		},

		setBookerInfoToggle() {
			this.isBookerInfoOpened = !this.isBookerInfoOpened;
		},

		setIsMobile(value) {
			this.isMobile = value;
		},
		setConversionData(value) {
			this.conversionData = value;
		},

		setSearchResults(value) {
			this.searchResults = value;
		},

		setCartData(ref, value, contactInfo) {
			this.bookingRef = ref;
			this.cartItems = value;
			this.booker = contactInfo;
		},

		addCartItem(value) {

			const cartItemIndex = this.cartItems.findIndex((item) => item.ref === value.ref);
			if (cartItemIndex === -1) {
				this.cartItems.push({ ...value, ref: makeRef() });
			} else {
				this.cartItems[cartItemIndex] = value;
			}
			if (process.env.NODE_ENV !== 'production')
				return;

			this.updateCartItemCurrency();
		},

		removeCartItem(ref) {
			const conversionStore = useConversionStore();
			const { queries } = storeToRefs(conversionStore);
			this.cartItems = this.cartItems.filter((item) => item.ref !== ref);
			if (process.env.NODE_ENV !== 'production')
				return;
			apiPost(`/api/cart/upsert/${this.bookingRef}`, {
				content: { contactInfo: this.booker, bookingItems: this.cartItems, currency: this.currency, queries: queries.value },
			});
		},

		updateCartItemCurrency() {
			const conversionStore = useConversionStore();
			const currencyStore = useCurrencyStore();
			const { currency } = storeToRefs(currencyStore);
			const { queries } = storeToRefs(conversionStore);
			const { rates } = storeToRefs(useListingsStore());
			this.cartItems = this.cartItems.map((cartItem) => {
				const { rate, total, ratePlan } = cartItem;
				const { base, extra, currency: itemCurrency = 'USD', inc } = ratePlan;

				const newItem = {
					...cartItem,
					ratePlan: {
						inc,
						currency: currency.value,
						base: currencyConverter(rates.value, base, itemCurrency, currency.value),
						extra: currencyConverter(rates.value, extra, itemCurrency, currency.value),
					},
					rate: currencyConverter(rates.value, rate, itemCurrency, currency.value),
					total: currencyConverter(rates.value, total, itemCurrency, currency.value),
				};
				return newItem;
			});
			if (process.env.NODE_ENV !== 'production')
				return;
			if (this.cartItems.length > 0) {
				apiPost(`/api/cart/upsert/${this.bookingRef}`, {
					content: { contactInfo: this.booker, bookingItems: this.cartItems, currency: currency.value, queries: queries.value },
				});
			}
		},

		setBookerInfo(value) {
			this.booker = value;
		},
		toggleSelectDialog() {
			this.isSelectDiagOpen = !this.isSelectDiagOpen;
		},

		clearCart() {
			this.cartItems = [];
			this.conversionData = {
				value: 0,
				currency: 'USD',
				transaction_id: null,
			};
		},

		setSearchData(value) {
			this.searchData = value;
		},

		setPlaceSearchCache(value) {
			const cache = getUniqueByKey([...this.placeSearchCache, ...value], 'place_id');
			this.placeSearchCache = cache;
		},
		setIsGranted() {
			this.isGranted = true;
		}
	},

	getters: {
		getBookingRef: (state) => state.bookingRef,
		getIsBookingDiagOpen: (state) => state.isBookingDiagOpen,
		getConversionData: (state) => state.conversionData,
		getBookerInfo: (state) => state.booker,
		isBookingInitiated: (state) => state.booker.name !== null,
		getCartCount: (state) => state.cartItems.length,
		getCartItems: (state) => state.cartItems,
		getBookerInfoToggle: (state) => state.isBookerInfoOpened,
		getSearchResults: (state) => state.searchResults,
		getSearchData: (state) => state.searchData,
		getPlaceSearchCache: (state) => state.placeSearchCache,
		getUserCountry: (state) => state.userCountry,
		getUserCurrency: (state) => state.currency,
		getCurrencyData: (state) => state.currencyData,
		getIsSelectDiagOpen: (state) => state.isSelectDiagOpen,
		isConsentGranted: (state) => state.isGranted,

	},
	persist: {
		storage: persistedState.localStorage,
	},
});
